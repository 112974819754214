import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <h1 className="mt-5">Seite nicht gefunden</h1>
    <p>Die Seite die du versucht hast aufzurufen, existiert nicht.</p>
  </Layout>
)

export default NotFoundPage
